/* Updated App.css */
body {
  background-color: rgb(29, 53, 8); /* Dark background */
  color: #E0E0E0; /* Light text */
  font-family: 'Roboto', sans-serif; /* Modern font */
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

/* h4, h6 {
  color: red;
} */

.section-heading{
  color: rgb(250, 255, 185);
}

.section-text{
  /* color: lightgreen; */
}

.floating-backplate {
  
}

.stakco-card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); /* Adjusted box-shadow */
  color: brown; /* Text color */
  background-color: lightyellow; /* Background color */
}

/* Add this to your App.css */

.hexagon-image {
  width: 150%; /* Increase the size */
  height: 150%; /* Ensure the image maintains aspect ratio */
  animation: none; /* Disable rotation */
  position: absolute; /* Maintain positioning */
  top: -25%; /* Adjust positioning if necessary */
  left: -25%; /* Adjust positioning if necessary */
  z-index: 1; /* Ensure it stays in the background if needed */
}

.App-logo1, .App-logo2, .App-logo3 {
  height: 40vmin;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo1 {
    animation: App-logo-spin infinite 20s linear;
  }
  .App-logo2 {
    animation: App-logo-spin infinite 40s linear;
  }
  .App-logo3 {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E0E0E0;
}

.App-link {
  color: #00B8D4; /* Accent color for links */
  text-decoration: none;
}

.App-link:hover {
  text-decoration: underline;
}

/* Rotating Image Enhancements */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.StyledRotatingImage {
  filter: blur(2px); /* Subtle blur for smoothness */
}

/* Card Styles */
.MuiCard-root {
  background-color: #1E1E1E;
  color: #E0E0E0;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
  font-weight: 600;
}

.MuiTypography-body1, .MuiTypography-body2 {
  font-weight: 300;
  line-height: 1.6;
}

/* Button Styles */
.MuiButton-root {
  background-color: #00B8D4;
  color: #FFFFFF;
  border-radius: 50px;
  padding: 10px 20px;
  text-transform: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.MuiButton-root:hover {
  background-color: #008CBA;
}

@media (max-width: 600px) {
  .StyledRotatingImage {
    width: 100%;
    height: 100%;
  }

  .hexagon-image {
    width: 200%; /* Ensure it scales correctly */
    height: auto;
    top: -50%;
    left: -50%;
  }
}

